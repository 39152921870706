import { api } from 'boot/axios';
import {
  DocumentDto,
  DocusignTemplateDto,
  DocusignOneTemplateDto,
  RequiresCustomerSignatureDto,
} from 'src/core/types';

const resource = '/documents';

const documentsService = {
  getDocusignTemplatesList: async (): Promise<DocusignTemplateDto[]> => {
    const res = await api.get(`${resource}/docusign-templates`);
    return res.data.data;
  },
  getDocusignTemplate: async (
    templateId: string
  ): Promise<DocusignOneTemplateDto> => {
    const res = await api.get<DocusignOneTemplateDto>(
      `${resource}/docusign-template/${templateId}`
    );
    return res.data;
  },
  createDocumentForRequest: async (documentData: {
    requestId: string;
    document: DocumentDto;
  }): Promise<{ document: DocumentDto }> => {
    const res = await api.post<{ document: DocumentDto }>(
      `${resource}`,
      documentData
    );
    return res.data;
  },
  getDocumentsToSign: async (
    requestId: string
  ): Promise<{
    data: { requiresCustomerSignature: RequiresCustomerSignatureDto[] };
  }> => {
    const res = await api.get(`${resource}/documents-to-sign/${requestId}`);
    return res.data;
  },
  signDocumentsForRequest: async (
    documentData: DocumentDto,
    requestId: string
  ): Promise<{ url: string }> => {
    const res = await api.post(
      `${resource}/docusign-loa-embedded/${requestId}`,
      documentData
    );
    return res.data;
  },
  async getRequestDocument(id?: number): Promise<{
    type: string;
    url: string;
    filename: string;
  }> {
    const response = await api.get(`/documents/${id?.toString()}`);
    return response.data;
  },
};

export { documentsService };
