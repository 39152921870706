import { RouteRecordRaw } from 'vue-router';
import dashboardRoutes from './dashboardRoutes';
import addRequestRoutes from './addRequestRoutes';
import ServerError from './InternalServerError.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('src/core/layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('src/core/pages/HomePage.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('src/account/pages/LoginPage.vue'),
        meta: {
          auth: false,
        },
      },
      {
        path: 'user/verify/:token',
        name: 'VerifyUser',
        component: () => import('src/account/pages/VerifyUser.vue'),
      },
      {
        path: 'not-deregulated-market',
        name: 'NotDeregulated',
        component: () =>
          import('src/requests/pages/NewRequest/DeregulatedServiceWarning.vue'),
      },
      {
        path: 'go-to-breakerbox',
        name: 'GoToBreakerbox',
        component: () =>
          import('src/requests/pages/NewRequest/GoToBreakerbox.vue'),
      },
      {
        path: 'setup-utility-provider',
        name: 'SetupUtilityProvider',
        component: () =>
          import('src/requests/pages/NewRequest/SetupUtilityProvider.vue'),
      },
      {
        path: 'interstitial/:stateId',
        name: 'Interstitial',
        component: () =>
          import('src/requests/pages/NewRequest/InterstitialPage.vue'),
      },
      {
        path: 'password/forgot',
        name: 'RequestForgotPassword',
        component: () =>
          import('src/account/pages/ForgotPassword/ForgotPassword.vue'),
      },
      {
        path: 'password/reset/:resetCode',
        name: 'ResetPassword',
        component: () =>
          import('src/account/pages/ForgotPassword/ResetPassword.vue'),
      },
      {
        path: 'password/setup/:resetCode',
        name: 'SetupPassword',
        component: () =>
          import('src/account/pages/ForgotPassword/SetupPassword.vue'),
      },
      {
        path: 'what-we-do',
        name: 'WhatWeDo',
        component: () => import('src/static-pages/pages/WhatWeDoPage.vue'),
      },
      {
        path: 'about',
        name: 'AboutUs',
        component: () => import('src/static-pages/pages/AboutUs.vue'),
      },
      {
        path: '/terms-of-use',
        name: 'TermsOfUse',
        component: () => import('src/static-pages/pages/TermsOfUsePage.vue'),
      },
      {
        path: 'terms',
        name: 'TermsOfService',
        component: () =>
          import('src/static-pages/pages/TermsOfServicePage.vue'),
      },
      {
        path: 'privacy/:toSection?',
        name: 'PrivacyPolicy',
        component: () => import('src/static-pages/pages/PrivacyPolicyPage.vue'),
      },
      {
        path: 'license-info',
        name: 'LicenseInformation',
        component: () =>
          import('src/static-pages/pages/LicenseInformationPage.vue'),
      },
      {
        name: 'ServerError',
        path: '/500',
        component: ServerError,
      },
      // Always leave this as last one,
      // but you can also remove it
      {
        path: '/:catchAll(.*)*',
        component: () => import('src/core/pages/NotFoundError.vue'),
        props: { goTo: 'homepage' }
      },
    ],
  },
  ...dashboardRoutes,
  ...addRequestRoutes,
];

export default routes;
