import { store } from 'quasar/wrappers';
import { createPinia } from 'pinia';
import { cloneDeep } from 'lodash-es';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(() => {
  const pinia = createPinia();

  // You can add Pinia plugins here
  // pinia.use(SomePiniaPlugin)
  pinia.use(piniaPluginPersistedstate);
  pinia.use(({ store }) => {
    const initialState = cloneDeep(store.$state);
    store.$reset = () => store.$patch(cloneDeep(initialState));
  });

  return pinia;
});
