import { boot } from 'quasar/wrappers';
import axios, { AxiosInstance, AxiosError, type AxiosResponse } from 'axios';
import * as Sentry from '@sentry/vue'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({ baseURL: process.env.API_URL });
api.defaults.headers.common['X-User-Timezone'] = 'America/New_York';

export default boot(({ app, router }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if(error.response?.status === 500){
        router.push('/500');
      }
      Sentry.captureException(error);
      return Promise.reject(error);
    },
  );

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
