<template>
  <impersonation-frame>
    <router-view />
    <div
      v-if="showScrollInfo && timeoutHelper && $q.screen.width > 700"
      class="view-more"
    >
      <div data-cy="scroll-for-more" class="scroll-for-more">
        <q-icon name="expand_more" />
        <span>Scroll for more</span>
      </div>
    </div>
  </impersonation-frame>
</template>

<script setup lang="ts">
import { useMeta } from 'quasar';
import ImpersonationFrame from 'src/core/layouts/ImpersonationFrame.vue';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { useRequestsStore } from 'src/requests/stores/requests';

const requestStore = useRequestsStore();
const requestItem = computed(() => requestStore.getRequest);

const route = useRoute();
const metaData = {
  title: 'Arise Energy',
  titleTemplate: (title: string) => `Arise Energy | ${title}`,
  meta: {
    description: {
      name: 'description',
      content: 'Trusted Commercial Electricity Company for Businesses',
    },
    keywords: {
      name: 'keywords',
      content: 'Arise Energy, electricity, plans, business, commercial, company',
    },
  },
  script: {
    ldJson: {
      type: 'application/ld+json',
      innerHTML: `{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Arise Energy",
  "url": "http://ariseenergy.com/",
  "logo": "https://ariseenergy.com/assets/colored-horizontal.89e32332.svg",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+1 (833) 275-7269",
    "contactType": "customer service"
  },
  "description": "Arise Energy is committed to providing innovative solutions in energy deregulation and retail electricity market. We aim to empower consumers by offering efficient energy solutions and brokerage services. Our team of experts ensures that our clients receive the best options tailored to their needs, promoting sustainability and cost-effectiveness.",
  "sameAs": [
    "https://www.facebook.com/ariseenergy",
    "https://twitter.com/ariseenergy",
    "https://www.linkedin.com/company/ariseenergy"
  ],
  "knowsAbout": [
    "https://en.wikipedia.org/wiki/Electricity_market_deregulation",
    "https://en.wikipedia.org/wiki/Retail_electricity_market",
    "https://en.wikipedia.org/wiki/Energy_broker"
  ],
  "areaServed": [
    {
      "@type": "State",
      "name": "California"
    },
    {
      "@type": "State",
      "name": "Connecticut"
    },
    {
      "@type": "State",
      "name": "Delaware"
    },
    {
      "@type": "State",
      "name": "Georgia"
    },
    {
      "@type": "State",
      "name": "Illinois"
    },
    {
      "@type": "State",
      "name": "Indiana"
    },
    {
      "@type": "State",
      "name": "Maine"
    },
    {
      "@type": "State",
      "name": "Maryland"
    },
    {
      "@type": "State",
      "name": "Massachusetts"
    },
    {
      "@type": "State",
      "name": "Michigan"
    },
    {
      "@type": "State",
      "name": "New Hampshire"
    },
    {
      "@type": "State",
      "name": "New Jersey"
    },
    {
      "@type": "State",
      "name": "New York"
    },
    {
      "@type": "State",
      "name": "Ohio"
    },
    {
      "@type": "State",
      "name": "Pennsylvania"
    },
    {
      "@type": "State",
      "name": "Rhode Island"
    },
    {
      "@type": "State",
      "name": "Texas"
    },
    {
      "@type": "State",
      "name": "Virginia"
    },
    {
      "@type": "AdministrativeArea",
      "name": "Washington D.C."
    }
  ]
}`
    }
  },
};
useMeta(metaData);

const showScrollInfo = ref(true);

const handleScroll = () => {
  if (window.scrollY > 0) {
    showScrollInfo.value = false;
  }
};

watchEffect(() => {
  if (route.name === 'Home') {
    showScrollInfo.value = true;
  } else if (route.name === 'Recommendation' || route.name === 'PlanDetails') {
    if (
      requestItem.value.workflowState === 'gathering_info' ||
      requestItem.value.workflowState === 'awaiting_responses' ||
      requestItem.value.workflowState === 'reviewing_request'
    ) {
      showScrollInfo.value = false;
    } else {
      showScrollInfo.value = true;
    }
  } else {
    showScrollInfo.value = false;
  }
});

const timeoutHelper = ref(false);

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  setTimeout(
    () => {
      timeoutHelper.value = true;
    },
    route.name ? 2000 : 0
  );
});
</script>
