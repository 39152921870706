<template>
  <div
    class="error-page"
  >
    <div class="error-wrapper flex row justify-between">

      <div class="error-wrapper-leftside col-md-6">
        <img
              :src="image"
              alt="see-platform-img"
              class="stylized-version-dashboard"
            />
      </div>
      <div class="error-wrapper-rightside col-md-6">
        <div class="error-message-number">500</div>
        <h1 class="error-message-1">Internal Server Error</h1>
        <p class="error-message-2">There are some technical issues while processing your request.</p>

        <q-btn
          class="error-page-button"
          color="primary"
          text-color="white"
          unelevated
          to="/"
          label="Go to homepage"
          no-caps
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const image = new URL(
        '/src/assets/img/error-img.png',
        import.meta.url
      ).href;
</script>

<style scoped lang="scss">
  .error-page{
    padding: 64px 36px;
    @media screen and (min-width: $breakpoint-xs + 1px ) {
      padding: 60px 200px;
    }
    @media screen and (min-width: $breakpoint-sm + 1px ) {
      padding: 120px 60px;
    }
    @media screen and (min-width: $breakpoint-md + 1px ) {
      padding: 60px;
    }

  }

  .error-wrapper-leftside, .error-wrapper-rightside{
    @media screen and (max-width: $breakpoint-sm ) {
      text-align: center;
      width: 100%;
    }
  }

  .error-wrapper-leftside {
    @media screen and (min-width: $breakpoint-sm + 1px ) {
      padding-left: 100px;
    }
    img{
      width: 60%;
      @media screen and (min-width: $breakpoint-xs + 1px ) {
        width: 50%;
      }
      @media screen and (min-width: $breakpoint-sm + 1px ) {
        width: 80%;
      }
    }
  }

  .error-wrapper-rightside {
    @media screen and (min-width: $breakpoint-sm + 1px ) {
      padding-right: 108px;
    }
    .error-message-number{
      color: $primary;
      font-size: 100px;
      font-weight: 600;
      @media screen and (min-width: $breakpoint-xs + 1px ) {
        font-size: 160px;
      }
    }
    .error-message-1{
      font-size: 22px;
      font-weight: 600;
      line-height: 1.5;
      margin-top: 0;
      @media screen and (min-width: $breakpoint-xs + 1px ) {
        font-size: 26px;
      }
    }

    .error-message-2{
      font-size: 16px;
      line-height: 1.63;
    }

    .q-btn{
      padding: 14px 24px;
      @media screen and (min-width: $breakpoint-xs + 1px ) {
        margin-top: 8px;
      }
    }
  }


</style>
