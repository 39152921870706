<template>
  <div class="impersonation-frame" :class="{ active: hasParentWindow }">
    <p v-if="hasParentWindow" class="impersonation-frame__user">
      <template v-if="userName && email">
        Impersonating user {{ userName }} ({{ email }})
      </template>
    </p>

    <div v-if="hasParentWindow" class="close" @click="stopImpersonation">
      <q-icon name="close" />
      <span>Close</span>
    </div>

    <div>
      <slot></slot>
    </div>

    <span v-if="hasParentWindow" class="impersonation-frame__bottom"></span>

    <div v-if="displayLoader" class="q-pa-md loader">
      <div class="q-gutter-md row loader__spinner">
        <q-spinner color="primary" size="3em" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeMount, watch } from 'vue';
import { useRoute } from 'vue-router';
import type { Ref } from 'vue';
import { aqLocalStorage } from 'src/boot/aq-storage';


const impersonateOrigin = process.env.IMPERSONATION_ORIGIN;
const route = useRoute();
const hasParentWindow = ref(false);
const pageReferrer = ref('');
const userName: Ref<string | null> = ref(null);
const email: Ref<string | null> = ref(null);
let parentWindow: Window | undefined;

const removeImpersonationFlags = (): void => {
  aqLocalStorage.removeItem('userName');
  aqLocalStorage.removeItem('email');
  aqLocalStorage.removeItem('parent-origin');
};
const getUserNameAndEmail = (): void => {
  userName.value = aqLocalStorage.getItem('userName');
  email.value = aqLocalStorage.getItem('email');
};
const stopImpersonation = (): void => {
  if (parentWindow && impersonateOrigin) {
    parentWindow?.postMessage('stop_impersonation', impersonateOrigin);
  }
};

const displayLoader = ref(true);
const getReferer = (): void => {
  pageReferrer.value = String(aqLocalStorage.getItem('referrer'));
  if (pageReferrer.value.endsWith('/')) {
    pageReferrer.value = pageReferrer.value.slice(0, -1);
  }
};

const activateImpersonationFrame = (): void => {
  const parentOrigin = aqLocalStorage.getItem('parent-origin');
  if (
    parentWindow &&
    impersonateOrigin === parentOrigin &&
    pageReferrer.value === parentOrigin
  ) {
    hasParentWindow.value = true;
    getUserNameAndEmail();
    removeImpersonationFlags();
  }
};

watch(route, () => {
  if (route.name === 'Dashboard' || route.name === 'Recommendation') {
    aqLocalStorage.removeItem('referrer');
    displayLoader.value = false;
  }
});

onBeforeMount(() => {
  parentWindow = window.opener;
  const parentOrigin = aqLocalStorage.getItem('parent-origin');

  if (!parentWindow) {
    displayLoader.value = false;
    return;
  }

  if (parentWindow && parentOrigin !== impersonateOrigin) {
    setTimeout(() => {
      displayLoader.value = false;
    }, 3000);
  }
});

onMounted(async () => {
  getReferer();
  activateImpersonationFrame();
});
</script>
