import {
  CompanyDto,
  PaginationDto,
  RequestAddressDto,
  RequestLocationDto,
  SortOrder,
  UDC,
} from './index';

export interface RequestDto {
  requestId?: number;
  requestUuid?: string;
  userId?: number;
  accountId?: number;
  workflowState?: RequestDtoWorkflowStateEnum | string;
  commodity: string;
  riskToleranceProfile: RequestDtoRiskToleranceProfileEnum;
  createdAt?: Date;
  updatedAt?: Date;
  deleted?: boolean;
  expertiseLevel: RequestDtoExpertiseLevelEnum;
  desiredContractLengths: number[];
  isRenewal: boolean;
  requestType?: string;
  requestMethod?: string;
  requestTime?: Date;
  mostRecentActivity?: Date;
  mostRecentQuote?: Date;
  requestedBy?: number;
  locationStateId?: number;
  responsesAsap: boolean;
  responsesDueBy?: Date;
  timezone: string;
  notes?: string;
  company?: CompanyDto;
  requestAddress?: RequestAddressDto;
  requestLocations: RequestLocationDto[];
  state?: State;
}

export interface RequestData {
  data: RequestDto[];
  pagination: PaginationDto;
  metadata: RequestMetadata;
}

export enum RequestDtoWorkflowStateEnum {
  GatheringInfo = 'gathering_info',
  ReviewingRequest = 'reviewing_request',
  AwaitingResponses = 'awaiting_responses',
  EvaluatingResponses = 'evaluating_responses',
  PurchaseRequested = 'purchase_requested',
  AwaitingCustomerSignature = 'awaiting_customer_signature',
  AwaitingContractAcceptance = 'awaiting_contract_acceptance',
  Purchased = 'purchased',
}
/**
 * @export
 * @enum {string}
 */
export enum RequestDtoRiskToleranceProfileEnum {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}
/**
 * @export
 * @enum {string}
 */
export enum RequestDtoExpertiseLevelEnum {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface RequestQuery {
  perPage?: number;
  page?: number;
  sortBy?: string;
  sortDir?: SortOrder;
  search?: string;
  hasExceptions?: boolean;
  hasUnclearedExceptions?: boolean;
  id?: number;
  include?: string[];

  rowsNumber?: number;
  rowsPerPage?: number;
  descending?: boolean;
}

export interface State {
  stateId: number;
  countryId: number;
  stateName: string;
  iso3166_2: string;
}

export interface WorkflowState {
  id: number;
  name: string;
  value: string;
}

export interface RequestMetadata {
  activeRequests: string;
  purchasedRequests: string;
  expiredRequests: string;
}

export interface Location {
  udcId: number;
  udc?: UDC;
  udcName?: string;
  editMode?: boolean;
  accountNumber: string;
  requestedStartMonth: string;
  startDate?: string;
  stopContractByMonth?: string;
  endDate?: string;
  customerNameKey: string;
  estimatedUsage: string;
  utilityProvider: string;
  address: {
    addressId?: 0;
    addressStreet1: string;
    addressStreet2: string;
    addressCity: string;
    addressState: string;
    addressCountry: string;
    addressPostCode: string;
  };
}

export interface LocationRow {
  index?: number;
  service_address: string;
  utility: string;
  customer_name_key: string;
  account_number: string | number;
  start_month: string;
  stopContractByMonth?: string;
  end_month?: string;
  annual_usage: string | number;
}

interface IObjectKeys {
  [key: string]: number | string | Date | null;
}

export interface ForecastDetail extends IObjectKeys {
  usageMonth: string;
  usage: number;
  usageUOM: string;
  supplyCost: number;
  supplyCostUOM: string;
}

export interface HubSpotConfirmation {
  success: boolean;
  filename: string;
}
