import { defineStore } from 'pinia';
import {
  PaginationDto,
  RequestDto,
  RequestMetadata,
  RequestQuery,
  WorkflowState,
} from 'src/core/types';
import { requestService, documentsService } from 'src/core/services';

import { RequestDtoWorkflowStateEnum } from 'src/core/types/request-dto';

export type RequestsState = {
  requests: RequestDto[];
  pagination: PaginationDto;
  // eslint-disable-next-line
  request: any;
  workflowStatuses: WorkflowState[];
  requestsMetadata: RequestMetadata;
  // eslint-disable-next-line
  contractRequest: any;
  // eslint-disable-next-line
  requestDocuments: any;
  // eslint-disable-next-line
  requestDocument: any;
  // eslint-disable-next-line
  historicalData: any;
  tax: string | null;
};

export const useRequestsStore = defineStore('requests', {
  state: () =>
    ({
      historicalData: [],
      workflowStatuses: [
        { id: 1, name: 'Gathering information', value: 'gathering_info' },
        { id: 2, name: 'Reviewing request', value: 'reviewing_request' },
        { id: 3, name: 'Awaiting responses', value: 'awaiting_responses' },
        { id: 4, name: 'Evaluating responses', value: 'evaluating_responses' },
        { id: 5, name: 'Purchase requested', value: 'purchase_requested' },
        {
          id: 6,
          name: 'Signature required',
          value: 'awaiting_customer_signature',
        },
        {
          id: 7,
          name: 'Awaiting contract acceptance',
          value: 'awaiting_contract_acceptance',
        },
        { id: 8, name: 'Purchased', value: 'purchased' },
      ],
      request: {
        workflowState: 'gathering_info',
      },
      requests: [],
      pagination: {
        total: 1,
        lastPage: 1,
        perPage: 25,
        currentPage: 1,
        from: 0,
        to: 25,
      },
      requestsMetadata: {
        activeRequests: '',
        purchasedRequests: '',
        expiredRequests: '',
      },
      contractRequest: {},
      requestDocuments: {},
      requestDocument: {},
      tax: null,
    } as RequestsState),

  getters: {
    getRequests(state) {
      return state.requests;
    },

    getRequestsMetadata(state) {
      return state.requestsMetadata;
    },

    getRequest(state) {
      return state.request;
    },
    getRequestDocuments(state) {
      return state.requestDocuments;
    },
    getRequestDocument(state) {
      return state.requestDocument;
    },
    getPagination(state) {
      return state.pagination;
    },
    completeRequestAddress(state) {
      if (state.request.requestAddresses.length) {
        return `${state.request.requestAddresses[0].addressStreet1}\n${
          state.request.requestAddresses[0].addressStreet2
            ? state.request.requestAddresses[0].addressStreet2 + '\n'
            : ''
        }${state.request.requestAddresses[0].addressCity}, ${
          state.request.requestAddresses[0].addressState
        } ${state.request.requestAddresses[0].addressPostCode}`;
      }
      return '';
    },
    getSignatoryDetails(state) {
      if (state.contractRequest?.legalEntityContacts) {
        const {
          firstName = '',
          lastName = '',
          emailAddress = '',
        } = state.contractRequest.legalEntityContacts.signatory.contacts[0];
        return {
          name: `${firstName} ${lastName}`,
          email: emailAddress,
        };
      }
    },
    getRequestHasQuotes(state) {
      switch (state.request.workflowState) {
        case RequestDtoWorkflowStateEnum.GatheringInfo:
        case RequestDtoWorkflowStateEnum.ReviewingRequest:
        case RequestDtoWorkflowStateEnum.AwaitingResponses:
          return false;
        default:
          return true;
      }
    },
    getRequestPurchaseStatus(state) {
      switch (state.request.workflowState) {
        case RequestDtoWorkflowStateEnum.AwaitingContractAcceptance:
        case RequestDtoWorkflowStateEnum.AwaitingCustomerSignature:
        case RequestDtoWorkflowStateEnum.PurchaseRequested:
        case RequestDtoWorkflowStateEnum.Purchased:
          return true;
        default:
          return false;
      }
    },
    getRequestDetailsStatus(state) {
      switch (state.request.customerRequestStatus) {
        case RequestDtoWorkflowStateEnum.Purchased:
        case RequestDtoWorkflowStateEnum.Expired:
          return state.request.customerRequestStatus;
        default:
          return state.request.workflowState;
      }
    },

    getTaxText(state) {
      return state.tax;
    },
  },

  actions: {
    async setRequests(
      id: number,
      payload: RequestQuery,
      filters: string
    ): Promise<void> {
      const response = await requestService.getRequests(id, payload, filters);
      this.requests = response.data;
      this.pagination = response.pagination;
      this.requestsMetadata = response.metadata;
    },

    async setRequest(id: number, requestId: string): Promise<void> {
      this.request = await requestService.getRequest(id, requestId);
    },

    async setContractRequest(id: number, requestId: string): Promise<void> {
      this.contractRequest = await requestService.getContractRequest(
        id,
        requestId
      );
    },

    async setHistoricalData(id: number, requestId: number): Promise<void> {
      this.historicalData = await requestService.getHistoricalData(
        id,
        requestId
      );
    },

    async setRequestDocuments(id: string): Promise<void> {
      this.requestDocuments = await documentsService.getDocumentsToSign(id);
    },

    async setRequestDocument(id?: number): Promise<void> {
      this.requestDocument = await documentsService.getRequestDocument(id);
    },

    setTaxText(text: string | null) {
      this.tax = text;
    },
  },
});
