import { RouteRecordRaw } from 'vue-router';

const dashboardRoutes: RouteRecordRaw[] = [
  {
    path: '/dashboard',
    component: () => import('src/core/layouts/DashboardLayout.vue'),
    children: [
      {
        name: 'unmapped-user',
        path: 'unmapped-user',
        component: () => import('src/account/pages/UnmappedUser.vue'),
      },
      {
        name: 'Dashboard',
        path: ':accountId(\\d+)',
        component: () => import('src/dashboard/pages/UserDashboard.vue'),
      },
      {
        name: 'Account',
        path: ':accountId(\\d+)/account',
        component: () => import('src/account/pages/AccountPage.vue'),
      },
      {
        name: 'Requests',
        path: ':accountId(\\d+)/requests',
        component: () => import('src/requests/pages/RequestsPage.vue'),
      },
      {
        name: 'Contracts',
        path: ':accountId(\\d+)/contracts',
        component: () => import('src/contracts/pages/AllContracts.vue'),
      },
      {
        name: 'ContractDetails',
        path: ':accountId(\\d+)/contracts/:id(\\d+)',
        props: true,
        component: () => import('src/contracts/pages/ContractDetails.vue'),
      },
      {
        name: 'Locations',
        path: ':accountId/locations',
        component: () =>
          import(
            'src/requests/locations/pages/LocationsView/LocationsView.vue'
          ),
      },
      {
        path: ':accountId(\\d+)/locations/:id(\\d+)',
        name: 'LocationDetails',
        component: () =>
          import(
            'src/requests/locations/pages/LocationDetails/LocationDetails.vue'
          ),
      },
      {
        path: ':accountId(\\d+)/locations/:id(\\d+)/contracts',
        name: 'LocationDetailsContracts',
        component: () =>
          import(
            'src/requests/locations/pages/LocationDetails/LocationDetailsContracts.vue'
          ),
      },
      {
        path: ':accountId(\\d+)/locations/:id(\\d+)/requests',
        name: 'LocationDetailsRequests',
        component: () =>
          import(
            'src/requests/locations/pages/LocationDetails/LocationDetailsRequests.vue'
          ),
      },
      {
        path: ':accountId(\\d+)/add-request',
        name: 'AddRequestDashboard',
        component: () => import('src/core/layouts/CreateRequest.vue'),
        children: [
          {
            path: 'pre-qualifiers',
            name: 'PrequalifiersDashboard',
            component: () =>
              import('src/requests/pages/NewRequest/Prequalifiers.vue'),
          },
          {
            path: 'customer-preferences/:step',
            name: 'CustomerPreferencesDashboard',
            component: () =>
              import('src/requests/pages/NewRequest/CustomerPreferences.vue'),
          },
          {
            path: 'company',
            name: 'CompanyDetailsDashboard',
            component: () =>
              import(
                'src/requests/pages/NewRequest/AdminNewRequest/AdminCompanyDetails.vue'
              ),
          },
          {
            path: 'locations',
            name: 'RequestLocationsDashboard',
            component: () =>
              import(
                'src/requests/pages/NewRequest/AdminNewRequest/AdminLocationsForm.vue'
              ),
          },
          {
            path: 'summary/:requestId(\\d+)',
            name: 'RequestSummaryDashboard',
            props: true,
            component: () =>
              import('src/requests/pages/NewRequest/RequestSummary.vue'),
          },
        ],
      },
      // NEW ROUTES
      {
        path: ':accountId(\\d+)/create-request',
        name: 'AdminRequestDashboard',
        component: () => import('src/core/layouts/AdminCreateRequest.vue'),
        children: [
          {
            path: 'customer-preferences/:step',
            name: 'AdminCustomerPreferencesDashboard',
            component: () =>
              import(
                'src/requests/pages/NewRequest/AdminNewRequest/AdminCustomerPreferences.vue'
              ),
          },
          {
            path: 'company/:fromLocations?',
            name: 'AdminCompanyDetailsDashboard',
            component: () =>
              import(
                'src/requests/pages/NewRequest/AdminNewRequest/AdminCompanyDetails.vue'
              ),
          },
          {
            path: 'locations',
            name: 'AdminRequestLocationsDashboard',
            component: () =>
              import(
                'src/requests/pages/NewRequest/AdminNewRequest/AdminLocationsForm.vue'
              ),
          },
          {
            path: 'summary/:requestId(\\d+)',
            name: 'AdminRequestSummaryDashboard',
            props: true,
            component: () =>
              import('src/requests/pages/NewRequest/RequestSummary.vue'),
          },
        ],
      },
      // NEW ROUTES END
      {
        path: ':accountId(\\d+)/requests/:id(\\d+)/edit',
        props: true,
        component: () => import('src/requests/pages/RequestDetails.vue'),
        children: [
          {
            path: 'customer-preferences',
            name: 'CustomerPreferencesEditDashboard',
            component: () => import('src/account/pages/PreferencesEdit.vue'),
          },
          {
            path: 'locations',
            name: 'RequestLocationsEditDashboard',
            component: () =>
              import('src/requests/locations/pages/LocationsEdit.vue'),
          },
          {
            path: 'company',
            name: 'CompanyDetailsEditDashboard',
            component: () => import('src/companies/pages/CompanyEdit.vue'),
          },
        ],
      },
      {
        path: ':accountId(\\d+)/requests/:id(\\d+)',
        props: true,
        component: () => import('src/requests/pages/RequestDetails.vue'),
        redirect: {
          name: 'Recommendation',
        },

        children: [
          {
            alias: '',
            name: 'Recommendation',
            path: 'recommendation',
            component: () =>
              import('src/requests/recommendation/pages/RecommendationTab.vue'),
          },
          {
            name: 'AllPlans',
            path: 'all-plans',
            component: () => import('src/requests/plans/pages/AllPlans.vue'),
          },
          {
            name: 'Watchlist',
            path: 'watchlist',
            redirect: {
              name: 'WatchlistList',
            },
            component: () =>
              import('src/requests/watchlist/pages/WatchList.vue'),
            children: [
              {
                name: 'WatchlistList',
                path: 'list',
                component: () =>
                  import('src/requests/watchlist/pages/WatchListListTab.vue'),
              },
              {
                name: 'PriceHistory',
                path: 'price-history',
                component: () =>
                  import(
                    'src/requests/watchlist/pages/WatchListPriceHistoryTab.vue'
                  ),
              },
            ],
          },
          {
            name: 'Purchase',
            path: 'purchase',
            component: () => import('src/requests/purchase/pages/Purchase.vue'),
          },
          {
            name: 'Info',
            path: 'info',
            component: () => import('src/requests/summary/pages/InfoTab.vue'),
          },
        ],
      },
      {
        name: 'PlanDetails',
        path: ':accountId/requests/:id/:planId(\\d+)',
        props: true,
        component: () => import('src/requests/plans/pages/PlanDetails.vue'),
      },
      {
        name: 'PurchaseForm',
        path: ':accountId(\\d+)/requests/:id(\\d+)/:planId/purchase-plan',
        component: () => import('src/requests/purchase/pages/PurchaseForm.vue'),
      },
      {
        name: '404Page',
        path: '/dashboard/:accountId/404',
        component: () => import('src/core/pages/NotFoundError.vue'),
        props: { goTo: 'dashboard' },
      },
      {
        path: '/dashboard/:accountId/:catchAll(.*)*',
        redirect: '/dashboard/:accountId/404',
      },
    ],
    meta: {
      auth: true,
    },
  },
];

export default dashboardRoutes;
