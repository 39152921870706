declare global {  
    interface Window {  
      Intercom: any;  
    }  
  }; 
  
export const callIntercom = (method: any, ...args: any) => {
  if (process.env.INTERCOM_KEY) {
    const interval = setInterval(() => {
      if (window.Intercom) {
        window.Intercom(method, ...args);
        clearInterval(interval);
      }
    }, 100);
  }
};