import { RouteRecordRaw } from 'vue-router';

const addRequestRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('src/core/layouts/HomeLayout.vue'),
    children: [
      {
        path: '/create-account/:selectedState?/:selectedPostalCode?',
        name: 'CreateAccount',
        meta: {
          auth: false,
        },
        component: () =>
          import('src/requests/pages/NewRequest/CreateAccount.vue'),
      },
      {
        path: '/pre-qualifiers',
        name: 'NewPrequalifiers',
        meta: {
          auth: false,
        },
        component: () =>
          import('src/requests/pages/NewRequest/Prequalifiers.vue'),
      },
    ],
  },
  {
    path: '/add-request',
    component: () => import('src/core/layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'AddRequest',
        component: () => import('src/core/layouts/CreateRequest.vue'),
        children: [
          {
            path: 'pre-qualifiers',
            name: 'Prequalifiers',
            component: () =>
              import('src/requests/pages/NewRequest/Prequalifiers_old.vue'),
          },
          {
            path: 'customer-preferences/:step',
            name: 'CustomerPreferences',
            component: () =>
              import('src/requests/pages/NewRequest/CustomerPreferences.vue'),
          },
          {
            path: 'company',
            name: 'CompanyDetails',
            component: () =>
              import('src/requests/pages/NewRequest/CompanyDetails.vue'),
          },
          {
            path: 'locations',
            name: 'RequestLocations',
            component: () =>
              import('src/requests/pages/NewRequest/LocationsForm.vue'),
          },
          {
            path: 'customer',
            name: 'CustomerDetails',
            component: () =>
              import('src/requests/pages/NewRequest/CustomerDetails.vue'),
          },
          {
            path: 'summary/:requestId',
            name: 'RequestSummary',
            props: true,
            meta: {
              auth: true,
            },
            component: () =>
              import('src/requests/pages/NewRequest/RequestSummary.vue'),
          },
        ],
      },
    ],
  },
];
export default addRequestRoutes;
