import { api } from 'boot/axios';
import { RequestData, RequestDto, RequestQuery } from 'src/core/types';
import type { HubSpotConfirmation } from 'src/core/types';

const resource = '/accounts';

const requestService = {
  getRequests: async (
    id: number,
    params: RequestQuery,
    filters: string
  ): Promise<RequestData> => {
    const res = await api.get<RequestData>(
      `${resource}/${id}/requests?${filters}`,
      {
        params,
      }
    );
    return res.data;
  },

  getRequest: async (id: number, requestId: string): Promise<RequestDto[]> => {
    const res = await api.get<RequestDto[]>(
      `${resource}/${id}/requests/${requestId}`
    );
    return res.data;
  },

  notifyHubSpot: async (requestId: number): Promise<HubSpotConfirmation> => {
    const res = await api.post('marketing-integration/request-created', {
      requestId,
      eventTrigger: 'request_created',
    });
    return res.data;
  },

  createRequest: async (
    accountId: number,
    data: RequestDto
  ): Promise<RequestDto> => {
    const res = await api.post<RequestDto>(
      `${resource}/${accountId}/requests`,
      data
    );
    return res.data;
  },

  getUploadUrl: async (
    fileType: string,
    containerName: string
  ): Promise<string> => {
    const res = await api.get<{ uploadUrl: string }>(
      `documents/generate-upload-url?extension=${fileType}&containerName=${containerName}`
    );
    return res.data.uploadUrl;
  },

  // documents/generate-broker-loas/:requestId
  generateBrokerLOA: async (requestID: string): Promise<RequestDto> => {
    const res = await api.post<RequestDto>(
      `/documents/generate-broker-loas/${requestID}`
    );
    return res.data;
  },

  updateRequest: async (
    accountId: number,
    requestId: string,
    data: RequestDto
  ): Promise<RequestDto> => {
    const res = await api.put<RequestDto>(
      `${resource}/${accountId}/requests/${requestId}`,
      data
    );
    return res.data;
  },

  subtmitToSuppliers: async (
    accountId: number,
    requestId: string
  ): Promise<string> => {
    const res = await api.post(
      `${resource}/${accountId}/requests/${requestId}/submit-to-suppliers`
    );
    return res.data;
  },

  getContractRequest: async (
    accountId: number,
    requestId: string,
    contractTemplateId: number | string = ''
  ) => {
    const query =
      contractTemplateId !== ''
        ? `?contractTemplateId=${contractTemplateId}`
        : '';
    try {
      const res = await api.get(
        `${resource}/${accountId}/requests/${requestId}/contract-request${query}`
      );
      return res.data;
    } catch (e: unknown) {
      console.error(e);
    }
  },

  getHistoricalData: async (
    id: number,
    requestId: number
  ): Promise<RequestDto[]> => {
    try {
      const res = await api.get(
        `${resource}/${id}/requests/${requestId}/historical-usage/`
      );
      return res.data;
    } catch (e: unknown) {
      console.error(e);
    }
  },

  activateRequest: async (
    accountId: number,
    requestId: string
  ): Promise<{ success: boolean }> => {
    const res = await api.post(
      `${resource}/${accountId}/requests/${requestId}/activate-request`
    );
    return res.data;
  },
};

export { requestService };
