import { route } from 'quasar/wrappers';
import { aqSessionStorage } from 'src/boot/aq-storage';

import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';

import routes from './routes';

declare global {
  interface Window {
    _hsq: Array<[string, ...unknown[]]>;
  }
}
import { callIntercom } from '../composables/useIntercom';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function () {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  Router.afterEach(() => {
    callIntercom('update');
  });

  let flag = false;
  Router.onError((error) => {
    if (
      error.message?.includes('Failed to fetch dynamically imported module')
    ) {
      if (!flag) {
        flag = true;
        window.location.reload();
        flag = false;
      }
    }
  });

  Router.afterEach((to) => {
    // required for HubSpot tracking
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', to.path]);
    _hsq.push(['trackPageView']);

    if ('showIntercom' in to.query) {
      callIntercom('show');
    }

    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'gclid', 'fbclid', 'li_fat_id'];
    utmParams.forEach(param => {
      if (param in to.query && to.query[param] && typeof to.query[param] === 'string') {
        aqSessionStorage.removeItem(`mk_${param}`);
        aqSessionStorage.setItem(`mk_${param}`, decodeURIComponent(to.query[param]));
      }
    });
  });

  return Router;
});
